import Head from "next/head";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import planturaLogo from "~/assets/plantura-logo-full.svg";
import appConfig from "~/config/app";
import { Box, Card, Heading, Text } from "~/ui/components";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t("404.title")}</title>
      </Head>
      <Wrapper>
        <Container>
          <Box>
            <Box style={{ margin: "0 auto", padding: "20px" }}>
              <a href={"/"}>
                <Image src={planturaLogo} alt="Plantura logo" height="46" />
              </a>
            </Box>
            <Box>
              <Card variant="light" className="not-found-content__container">
                <Box space="large">
                  <Heading>{t("404.heading")}</Heading>
                  <Heading element="h5">{t("404.introductory")}</Heading>
                  <Text>
                    {t("404.message[0]")} &nbsp;
                    <a href={appConfig.publicBaseUrl}>{t("404.message[1]")}</a>
                  </Text>
                </Box>
              </Card>
            </Box>
          </Box>
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 420px;
  margin-bottom: 20px;
  text-align: center;

  & .not-found-content__container {
    padding: 36px;
  }

  @media (max-width: 480px) {
    width: 100%;

    & .not-found-content__container {
      border: initial;
      margin: 0 20px 20px 20px;
    }
  }
`;

export { NotFound };
